

.kustomerPlaceholder {
  z-index: 1;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  height: 60px;
  width: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 150ms ease-in-out 0s;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 1px 1px 3px);

  &.isLoading {
    img {
      opacity: 0.4;
    }
  }
}
